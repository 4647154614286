<template>
  <el-dialog
      title="调整"
      v-model="performanceinfo"
      width="62%"
      height="200px"
      center append-to-body @close='closeDialog'>
    <div class="el-dialog-div">
     <el-form   :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-height="100px" class="ruleForm">
		
		
		 <el-row>
		  <el-form-item>
		  			 <el-button  icon="el-icon-edit"  @click="ViewUpdate()" type="success" size="small">批量调整</el-button>
		  </el-form-item>
		  
		<el-form-item label="所属项目" prop="name">
							<el-input :readonly="true"  v-model="ruleForm.plot_name"></el-input>
		</el-form-item>
		  </el-row>
		  
	   
	    <el-row>
			<el-form-item label="用料表" prop="name">
			  <el-table :data="dataList" row-key="id" border  ref="multipleTable">
			    <el-table-column label="序号" width="50">
			  		<template v-slot="scope">
			  			{{ scope.$index+1 }}
			  		</template>
			  	</el-table-column>
			  	<el-table-column prop="plot_name" label="所属项目" width="150"></el-table-column>
			    <el-table-column prop="typeName" label="一级分类"   width="100"></el-table-column>
			    <el-table-column prop="secName" label="二级分类"  width="100"></el-table-column>
			    <el-table-column prop="quantity" label="数量"  width="50" ></el-table-column>
				 <el-table-column label="实际数量" width="100" >
					<template v-slot="scope">
					    <el-input v-model="scope.row.nowQuantity" onkeyup="value=value.replace(/[^0-9.]/g,'')"/>
					</template>
				 </el-table-column>
			  	<el-table-column prop="user_name" label="使用人"></el-table-column>
				<el-table-column prop="remark" label="备注"></el-table-column>
			  	<el-table-column prop="datetime" label="时间" value-format="YYYY-MM-DD"  width="120"></el-table-column>
			   <el-table-column fixed="right" label="操作" width="200">
			      <template v-slot="scope">
			        <el-button  icon="el-icon-edit"  @click="View(scope.row)" type="success" size="small">调整</el-button>
					<el-button  v-if="scope.row.quantity == 0" @click="deleteMaterial(scope.row)" type="danger" icon="el-icon-delete" size="mini">删除</el-button>
				  </template>
			    </el-table-column> 
			  </el-table>
			</el-form-item>
	   </el-row>
	   
	   <el-row>
		   <el-form-item>
		   		<el-button  icon="el-icon-add"  @click="ViewAdd()" type="success" size="small">批量新增</el-button>
		   </el-form-item>
		   <el-form-item label="" >
		     <el-button  @click="info()">选择物料使用人</el-button>
		   </el-form-item>
		   
		   <el-form-item label="物料使用人" prop="users_name">
		     <el-input readonly="true" style="width: 200px;" v-model="users_name"></el-input>
		   </el-form-item>
	   </el-row>
	   <el-row>
		  
		   <el-form-item label="物料信息" prop="comment" >
		   					  <el-input v-model="filterText" placeholder="请搜索物料信息" />
		   					  <el-tree
		   					    class="filter-tree"
		   					    :data="data"
		   					    :props="defaultProps"
		   					    :filter-node-method="filterNode"
		   					    ref="tree" show-checkbox @check="setCheckedNodes">
		   					  </el-tree>
		   </el-form-item>
		   
		   <el-form-item prop="comment" >
		   				  <el-table :data="checkAddData" style="width: 100%">
		   					 <el-table-column prop="name1" label="物料一级" width="180" />
		   					 <el-table-column prop="name2" label="物料二级" width="180" />
		   					 <el-table-column label="数量">
		   						 <template v-slot="scope">
		   						 		<el-input v-model="scope.row.quantity"></el-input>
		   						 </template>
		   					 </el-table-column>
		   					 <el-table-column>
		   						 <template v-slot="scope">
		   								   <el-button  icon="el-icon-delete"  @click="Deleteinfo(scope.row)" type="danger" size="mini">删除</el-button>
		   						 </template>
		   					 </el-table-column>
		   				   </el-table>
		   </el-form-item>
	   </el-row>
	   
    </el-form>
    <div class="clear"></div>
    </div>
    <div class="ucForm-group-ad">
      <div class="ucForm-group ucBtn-bg">
        <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog" ><i
            class="iImg"></i>返回</button>
      </div>
    </div>
  </el-dialog>
  <rep_repairs_addM @closerepairsaddM="closerepairsaddM"  v-if="repairsaddMDialogVisible"></rep_repairs_addM>
  
</template>
<script>
import rep_repairs_addM from './rep_repairs_addM.vue'
export default {
  created () {
    this.performanceinfo = true;
    this.clearinfo();
	this.getDatas();
    this.handleClickQuery();
  },
  data(){
    let self=this;
    return{
      position:'top',
	  comment:'',
	  stars_nums:'',
	  isShow:false,
      ruleForm:{
        id:this.$parent.id,
      },
	  srcList:[],
	  srcListFirst:[],
	  statementPicture:[],
	  totalCount:0,
	  dataList:[],
	  filterText:"",
	  data:[],
	  checkAddData:[],
	  plot_id:"",
	   repairsaddMDialogVisible:false,
	   users_name:"",
	   user_id:""
    }},
  methods: {
	  deleteMaterial(req){
	  	if(req.quantity != 0){
	  		this.$message("不可删除数量不为0的物料");
	  		return false;
	  	}
		this.$confirm('是否确认删除。', '提示', {
		  confirmButtonText: '确定',
		  cancelButtonText: '取消',
		  type: 'warning'
		}).then(() => {
			 var self = this;
			 var data = {}
			 data.cmd = "deleteMaterialInfo"
			 data.id = req.id;
			 this.$sknet.postJson(this.$skconf("cla.surl"), data, function(res) {
			 	self.$message(res.reason);
			 	this.getDatas();
			 }, null, this);
		}).catch(() => {});
	  	
	  	
	  },
	  closerepairsaddM:function(data){
	    this.users_name=data.user_name + "（"+data.mobile+"）";
	    this.user_id = data.id;
	    this.repairsaddMDialogVisible  = false;
	  },
	  info: function() {
	    this.repairsaddMDialogVisible = true;
	  },
	ViewAdd(){
		  let self = this;
		  var data = {};
		  data.id = this.$parent.id;
		  if(this.checkAddData.length == 0){
		  	 self.$message('请选择物料信息');
		  	 return;
		  }
		  if(this.user_id == ""){
		  	 self.$message('请设置物料使用人');
		  	 return;
		  }
		  var show = false;
		  for(var i=0;i<this.checkAddData.length;i++){
		  	if(this.checkAddData[i].quantity == ""){
		  		show = true;
		  		break;
		  	}
		  }
		  if(show){
		  	 self.$message('请填写物料数量');
		  	 return;
		  }
		  data.typeList = this.checkAddData;
		  data.cmd = "updateRmsMaterialCommon";
		  data.plot_id = this.plot_id;
		  data.user_id = this.user_id;
		  this.$sknet.postJson(this.$skconf("cla.surl"), data, function(res) {
		  	self.getDatas();
		  }, null, this);
	},
	ViewUpdate(){
		  var next = true;
		  for(var i=0;i<this.dataList.length;i++){
			  var req = this.dataList[i];
			  if(req.nowQuantity == undefined || req.nowQuantity == ""){
				 this.$message("第" + (i+1) + "行," + "请输入调整的实际用料数量");
				 next = false;
				 break;
			  }
		  }
		  if(next){
			  for(var i=0;i<this.dataList.length;i++){
				  var req = this.dataList[i];
				  var self = this;
				  var data = {}
				  data.cmd = "adjustmentMaterial"
				  data.id = req.id;
				  data.nowQuantity = req.nowQuantity;
				  this.$sknet.postJson(this.$skconf("cla.surl"), data, function(res) {
					  this.getDatas();
				  }, null, this);
			  }
			  
		  }	
		  
	},
	View(req){
		if(req.nowQuantity == undefined || req.nowQuantity == ""){
			this.$message("请输入调整的实际用料数量");
			return false;
		}
		var self = this;
		var data = {}
		data.cmd = "adjustmentMaterial"
		data.id = req.id;
		data.nowQuantity = req.nowQuantity;
		this.$sknet.postJson(this.$skconf("cla.surl"), data, function(res) {
			self.$message(res.reason);
			this.getDatas();
		}, null, this);
	},
	findRepairMember:function(data){
	  var req = {};
	  req.cmd = "findMmaterialTree";
	  req.plot_id = this.plot_id;
	  this.$sknet.postJson(this.$skconf("cla.surl"), req, function(res) {
	    console.log(res.datas.items);
		
		res.datas.items.map((item) => {
		  if(item.hasOwnProperty('children')){
			item.disabled = true;
		  }
		});
		this.data = res.datas.items;
		this.count = res.datas.count;
		this.statuss2 = res.datas.wxzts;
	
	  }, null, this);
	},
	Deleteinfo(item){
			  this.checkAddData.splice(item, 1);
			  console.log(item);
			  
			  var obj = {};
			  obj.count = 0;
			  obj.father_id=item.type_id;
			  obj.id= item.secn_id;
			  obj.label = item.name2;
			  obj.name = item.name2;
			  obj.start = 0;
			  
			  for(let i=0;i<this.data.length;i++){
			  	if(this.data[i].id == item.type_id){
			  		for(let j=0;j<this.data[i].children.length;j++){
						if(this.data[i].children[j].id == item.secn_id){
							this.$refs.tree.setChecked(this.data[i].children[j],false,true);
							break;
						}	
					}
			  	}
			  }
	},
	filterNode(value,data){
			  if (!value) return true
			    return data.label.includes(value)
	},
	setCheckedNodes(node,checked){
			console.log("node:"+node);
			let _i = -1;
			for(let i=0;i<this.checkAddData.length;i++){
				if(this.checkAddData[i].secn_id == node.id){
					_i = i;
					break;
				}
			}
			console.log("--" + _i);
			if(_i > -1){
				this.checkAddData.splice(_i,1);
			}else{
				let data = {};
				//"item-水暖件材料-2-6"
				data.type_id = node.father_id;
				for(let i=0;i<this.data.length;i++){
					if(this.data[i].id == node.father_id){
						data.name1 = this.data[i].name;
						break;
					}
				}
				
				data.secn_id = node.id;
				data.name2 = node.name;
				data.quantity = "";
				data.price = node.price;
				data.number = node.number;
				data.specifications = node.specifications;
				data.unit = node.unit;
				this.checkAddData.push(data);
			}
			console.log("--" + this.checkAddData);
	},
    handleClickQuery:function(data){
      this.dataList = [];
      var req = {};
      req.cmd = "searchRepairs";
      req.id=this.$parent.id;
      this.$sknet.postJson(this.$skconf("cla.surl"), req, function(res) {
        this.ruleForm = res.datas.items[0];
        this.plot_id = this.ruleForm.plot_id;
		this.findRepairMember();
       }, null, this);
    },
    closeDialog: function(){
      this.clearinfo();
      this.$emit('closerepairsView'); //通知父组件改变。
    },
    clearinfo:function(){
    },
	getDatas:function(){
	  this.dataList = [];
	  var req = {};
	  req.order_id = this.$parent.id;;
	  req.cmd = "searchMaterials";
	  req.start=0;
	  req.count=100;
	  this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
	    console.log(res);
	    this.dataList=res.datas.items;
	    this.totalCount=res.datas.totalCount;
	  }, null, this);
	},
  },
  mounted(){
  },
  watch: {
  		filterText(val) {
  			this.$refs.tree.filter(val);
  		}
  },
  components: {
	  rep_repairs_addM
  }
}
</script>
<style scoped>
.el-dialog-div {
  height: 60vh;
  overflow: auto;
  Margin:5px;
}
</style>