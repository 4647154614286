<template>

  <el-dialog
      title="查看"
      v-model="performanceinfo"
      width="80%"
      height="800px"
      center append-to-body @close='clearinfo'>
    <div class="el-dialog-div">
      <el-form ref="searchForm"  label-width="240" :inline="true" v-show="this.$parent.type_id == null">
      	  <el-row>
      		  <el-form-item label="选择项目:" prop="name">
      		    <el-select v-model="article" placeholder="请选择" style="width:200px" clearable @change="getNextType">
      		      <el-option v-for="data in optionstype" :key="data.plot_id" :label="data.plot_name" :value="data.plot_id"></el-option>
      		    </el-select>
      		  </el-form-item>
      		  <el-form-item label="一级分类:" prop="name">
      		    <el-select v-model="typeOne" placeholder="请选择" style="width:200px" @change="getSecType" >
      		      <el-option v-for="data in optionsype" :key="data.id" :label="data.name" :value="data.id"></el-option>
      		    </el-select>
      		  </el-form-item>
      		  <el-form-item label="二级分类:" prop="name">
      		    <el-select v-model="typeTwo" placeholder="请选择" style="width:200px" >
      		      <el-option v-for="data in secType" :key="data.id" :label="data.name" :value="data.id"></el-option>
      		    </el-select>
      		  </el-form-item>
      		  <el-form-item label="使用人:" prop="name"><el-input v-model="user_name" placeholder="请输入名称" style="width:200px " clearable></el-input>
      		  </el-form-item>
      	  </el-row>
      	  <el-row>
      		  <el-form-item label="时间查询:">
      		   <el-date-picker v-model="beginDate" type="date" style="height:35px" value-format="YYYY-MM-DD" ></el-date-picker><span style="padding: 0 15px;">-</span>
      		   <el-date-picker v-model="endDate" type="date" style="height:50px" value-format="YYYY-MM-DD" ></el-date-picker>
      		  </el-form-item>
      		  
      		  <el-form-item>
      		  	<el-button type="primary" @click="queryMembers()">搜索</el-button>
      		  </el-form-item>
      	  </el-row>
        
      </el-form>
     <el-table :data="dataList" row-key="id" border  ref="multipleTable">
       <el-table-column label="序号" width="100px">
     		<template v-slot="scope">
     			{{ scope.$index+1 }}
     		</template>
     	</el-table-column>
     	<el-table-column prop="order_id" label="订单号" >
     		<template v-slot="scope">
     			<a @click="orderDetail(scope.row)" style="cursor: pointer;">{{ scope.row.order_id}}</a>
     		</template>
     	</el-table-column>
     	<el-table-column prop="plot_name" label="所属项目" ></el-table-column>
       <el-table-column prop="typeName" label="一级分类" ></el-table-column>
       <el-table-column prop="secName" label="二级分类"></el-table-column>  
     	 <el-table-column prop="number" label="编号"></el-table-column>
     <!--  <el-table-column prop="unit" label="单位"></el-table-column>
     	<el-table-column prop="specifications" label="规格"></el-table-column> -->
     	<el-table-column prop="quantity" label="数量"></el-table-column>
       <el-table-column prop="price" label="价格"></el-table-column>
     	<el-table-column prop="total_price" label="总价"></el-table-column>
		  	<el-table-column prop="nowQuantity" label="原用料数量"></el-table-column>
			<el-table-column prop="adjustment" label="调整后数量"></el-table-column>
     	<el-table-column prop="user_name" label="使用人"></el-table-column>
     	<!-- <el-table-column prop="remark" label="备注"></el-table-column> -->
     	<!-- <el-table-column prop="datetime" label="时间" value-format="YYYY-MM-DD"></el-table-column> -->
		<el-table-column prop="userName" label="调整人"></el-table-column>
		<!-- <el-table-column prop="remark" label="备注"></el-table-column> -->
		<el-table-column prop="createTime" label="调整时间" value-format="YYYY-MM-DD"></el-table-column>
     </el-table>
    </div>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pagesize"
        layout="total,sizes,prev,pager,next,jmper"
        :total="totalCount">
    </el-pagination>
    <div class="ucForm-group-ad">
      <div class="ucForm-group ucBtn-bg">
        <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog" ><i
            class="iImg"></i>返回</button>
      </div>
    </div>

  </el-dialog>

</template>

<script>
export default {
  created () {
    this.performanceinfo = true;
    this.queryMembers();
    this.searchMaterialTypes();
    this.getplotname()
  },
  data(){
    let self=this;
    return{
      dataList:[],  //表格数据集
      user_name:null,   //综合查询条件参数
      article:'',
      optionstype:[],     //所属项目选项
      optionsype:[],
      secType:[],
      typeOne:this.$parent.type_id || null,  //选择的所属项目
      typeTwo:this.$parent.secn_id || null,
      beginDate:null,
      endDate:null,
      totalCount:0,          //总页数
      pagesize:10,        //每页数量
      currentPage:1,    //初始页
    }},

  methods: {
    getplotname:function(){
      var self = this;
      var redata = {};
      redata.cmd = "getProject";
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
        this.optionstype=datas.datas.items;
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
    //查询分类的方法
    searchMaterialTypes:function(){
      var self = this;
      var redata = {};
      redata.cmd = "searchMaterialTypes";
      if(this.article != "" && this.article != undefined){
    	redata.plot_id = this.article;
      }
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
    	  self.optionsype = [];
    	  self.optionsype.push({id:0,name:'请选择'});
    	  for(var i=0;i<datas.datas.items.length;i++){
    		  self.optionsype.push(datas.datas.items[i]);
    	  }
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
    getNextType:function(data){
    	this.searchMaterialTypes();
    },
    getSecType:function(data){
      var self = this;
      var redata = {};
      redata.father_id =data ;
    	self.secType=[];
      if(data==0){
    	  return;
      }
      redata.cmd = "searchSecTypes";
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
    	  self.secType.push({id:0,name:'请选择'});
    	  for(var i=0;i<datas.datas.items.length;i++){
             self.secType.push(datas.datas.items[i]);
    	  }
      }, 
      function(data) {
        self.$message(data.reason);
      }, self);
    },
    //条件查询的方法
    queryMembers:function(){
        this.dataList = [];
        var req = {};
    	req.type_id =this.typeOne;
    	req.secn_id = this.typeTwo;
    	req.user_name = this.user_name;
    	req.beginDate = this.beginDate;
    	req.endDate = this.endDate;
    	req.plot_id =this.article;
        req.cmd = "searchMaterialsHistoryRecord";
        req.start=(this.currentPage-1) * this.pagesize;
        req.count=this.pagesize;
        this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
          console.log(res);
          this.dataList=res.datas.items;
          this.totalCount=res.datas.totalCount;
    	  self.ids = res.datas.ids;
        }, null, this);
    },
    /*分页代码*/
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize)  //每页下拉显示数据
    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      this.handleClickQuery();
      console.log(this.currentPage)  //点击第几页

    },
    closeDialog: function(item){
		 this.performanceinfo = false;
      this.$emit('closerepairsView'); //通知父组件改变。
    },
    clearinfo:function(){
		 this.performanceinfo = false;
	   this.$emit('closerepairsView'); //通知父组件改变。
    },
  },
  mounted(){

  },
  components: {

  }
}
</script>

<style scoped>
.el-dialog-div {
  height: 30vh;
  overflow: auto;
  Margin:5px;
}
</style>